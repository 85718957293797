import { Typography } from "@mui/material";
import React from "react";

const Home = () => (
  <>
    <Typography variant="h4">Scenic Peaks Counseling</Typography>
    <Typography>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit...
    </Typography>
  </>
);

export default Home;
